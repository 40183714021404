<!-- Start Coming Soon Area -->
<section class="coming-soon">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h1>Site en Construction</h1>
                    <p>Le site sera très bientôt disponible.</p>

                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>

                    <!-- <form>
                        <input type="text" class="form-control" placeholder="Entrer votre adresse email" required>
                        <button type="submit">Souscrire</button>
                    </form> -->

                    <div class="col-lg-12">
                        <button type="submit" class="btn btn-primary contact" data-bs-toggle="modal" data-bs-target="#myModal2">Nous Contacter</button>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Coming Soon Area -->

<app-sidebar></app-sidebar>