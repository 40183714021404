<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Our Latest Blog</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class="icofont-double-left"></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class="icofont-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->