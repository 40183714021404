<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Gallery</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Gallery Area -->
<section class="gallery-area ptb-100">
    <div class="row m-0">
        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery1.jpg" alt="gallery">
                <a href="assets/img/gallery1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery2.jpg" alt="gallery">
                <a href="assets/img/gallery2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery3.jpg" alt="gallery">
                <a href="assets/img/gallery3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery4.jpg" alt="gallery">
                <a href="assets/img/gallery4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery5.jpg" alt="gallery">
                <a href="assets/img/gallery5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery6.jpg" alt="gallery">
                <a href="assets/img/gallery6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery7.jpg" alt="gallery">
                <a href="assets/img/gallery7.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery8.jpg" alt="gallery">
                <a href="assets/img/gallery8.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery1.jpg" alt="gallery">
                <a href="assets/img/gallery1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery2.jpg" alt="gallery">
                <a href="assets/img/gallery2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery3.jpg" alt="gallery">
                <a href="assets/img/gallery3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery4.jpg" alt="gallery">
                <a href="assets/img/gallery4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery5.jpg" alt="gallery">
                <a href="assets/img/gallery5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery6.jpg" alt="gallery">
                <a href="assets/img/gallery6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery7.jpg" alt="gallery">
                <a href="assets/img/gallery7.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery8.jpg" alt="gallery">
                <a href="assets/img/gallery8.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->