<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="icofont-close"></i></span></button>
                    <h2 class="modal-title" id="myModalLabel2"><a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a></h2>
                </div>

                <div class="modal-body">
                    <!-- <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li><a routerLink="/">Login</a></li>
                            <li><a routerLink="/">Register</a></li>
                            <li><a routerLink="/">FAQ</a></li>
                            <li><a routerLink="/">Logout</a></li>
                        </ul>
                    </div> -->

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Infomations de Contact</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="icofont-google-map"></i>
                                Adresse
                                <span>Douala - Cameroun</span>
                            </li>
                            <li>
                                <i class="icofont-email"></i>
                                Email
                                <span>infos@designatelier.org</span>
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                Phone
                                <span>+237 674 332 138</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connectes toi avec nous</h3>

                        <ul class="social-list">
                            <li><a href="https://www.facebook.com/Design-Atelier-103408309030072/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->