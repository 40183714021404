<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/{{logo}}" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ms-auto">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Home</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">SaaS Landing</a></li>

                                <li class="nav-item"><a routerLink="/app-landing" routerLinkActive="active" class="nav-link">App Landing</a></li>

                                <li class="nav-item"><a routerLink="/digital-marketing" routerLinkActive="active" class="nav-link">Digital Marketing</a></li>

                                <li class="nav-item"><a routerLink="/product-landing" routerLinkActive="active" class="nav-link">Product Landing</a></li>

                                <li class="nav-item"><a routerLink="/software-landing" routerLinkActive="active" class="nav-link">Software Landing</a></li>

                                <li class="nav-item"><a routerLink="/book-landing" routerLinkActive="active" class="nav-link">Book Landing</a></li>

                                <li class="nav-item"><a routerLink="/startup-agency" routerLinkActive="active" class="nav-link">Startup Agency</a></li>

                                <li class="nav-item"><a routerLink="/payment-processing" routerLinkActive="active" class="nav-link">Payment Processing</a></li>
                            </ul>
                        </li>

                        <li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a></li>

                                <li class="nav-item"><a routerLink="/gallery" routerLinkActive="active" class="nav-link">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/projects" routerLinkActive="active" class="nav-link">Portfolio</a></li>

                                <li class="nav-item"><a routerLink="/login" routerLinkActive="active" class="nav-link">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" routerLinkActive="active" class="nav-link">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/error" routerLinkActive="active" class="nav-link">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a></li>
                            </ul>
                        </li>

                        <li><a routerLink="/gallery" routerLinkActive="active">Gallery</a></li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Blog</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="contact-us" class="nav-link" routerLinkActive="active">Contact</a></li>
                    </ul>
                </div>

                <div class="mr-auto others-option">
                    <ul class="navbar-nav">
                        <li class="header-search-box">
                            <a href="javascript:void(0)" id="header-search-main" title="Search">
                                <i class="icofont-search-2"></i>
                            </a>
                        </li>

                        <li data-bs-toggle="modal" data-bs-target="#myModal2">
                            <div class="side-menu">
                                <span class="bar-1"></span>
                                <span class="bar-2"></span>
                                <span class="bar-3"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->